@import "~bootstrap/scss/bootstrap";
@import "../styles/devise.scss";
@import "../styles/common";

.content {
  img {
    max-width: 100%;
  }
}

.required:after {
  content: '*';
  color: red;
}

input[required]+label:after {
  content: '*';
  color: red;
}

.form-floating {
  &>.form-label {
    color: #646464;
  }
}

.custom-card-primary {
  border-radius: 10px;
  border-left: 8px #007bff solid;
  border-right: none;
  border-top: none;
  border-bottom: none;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.card-link {
  text-decoration: none;
  color: #000;

  &:hover {
    color: #000;
  }
}

.hidden {
  display: none;
}