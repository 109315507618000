.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
}

.content {
  padding: 3rem 0;
}
