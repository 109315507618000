.session {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #2c4fc7;
  &__content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 450px;
    padding: 1.5rem;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    justify-self: center;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .list_actions {
    list-style: none;
    margin: 0;
    padding: 0;
    a {
      text-decoration: none;
    }
  }
}